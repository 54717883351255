import {
  calcDiscountFromCouponOnPrice,
  calcPriceAfterDiscountOnPrice,
} from "../../utils/discounts";
import styles from "./CostSummary.module.scss";
import { useAtom } from "jotai";
import { loadableTax, savingOnChangePriceAtom, selectedPriceAtom } from "../../lib/product";
import { discountAtom } from "../../lib/discount";
import { useSearchParams } from "react-router-dom";
import { Interval } from "../../types/interval";
import { getCurrencySymbolAtom } from "../../lib/country";
import { intervalAtom } from "../../lib/interval";
import {
  savingsFormattedReadOnlyAtom,
  displayToggleWhenAnnualAtom,
  displayToggleBannerAtom,
} from "../../lib/plan-interval-switch";
import { toggleCoverageInterval } from "../../utils/plan-interval-switch";
import { CostSummaryAnnualToggle } from "../cost-summary-annual-toggle/CostSummaryAnnualToggle";
import { ReactComponent as PercentageIcon } from "../../assets/percentage-icon.svg";
import { DISCOUNT_CODE_2_YEAR } from "../../constants/promotion";
import { remoteSetLeadAtom } from "../../lib/lead";

export const CostSummary = () => {
  const [savingOnChangeCoverage] = useAtom(savingOnChangePriceAtom);
  const [savingsFormattedReadOnly] = useAtom(savingsFormattedReadOnlyAtom);
  const [interval] = useAtom(intervalAtom);
  const [discount, setDiscount] = useAtom(discountAtom);
  const [currencySymbol] = useAtom(getCurrencySymbolAtom);
  const [displayToggleBanner] = useAtom(displayToggleBannerAtom);
  const [displayToggleWhenAnnual] = useAtom(displayToggleWhenAnnualAtom);
  const [price] = useAtom(selectedPriceAtom);
  const [tax] = useAtom(loadableTax);
  const [_, remoteSetLead] = useAtom(remoteSetLeadAtom);
  const formattedPrice = price?.currentPrice ? (price.currentPrice / 100) : null;
  const taxRate = 1 + (tax.state === "hasData" ? tax.data : 0);
  const formattedPriceWithTax = formattedPrice != null ? formattedPrice * taxRate : null;

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={styles.separator}>
      <div className="d-flex justify-content-between fs-6">
        <span className={styles.caption}>Sub Total</span>
        <span>
          {currencySymbol}
          {formattedPrice?.toFixed(2) ?? ""}
        </span>
      </div>
      <div className="d-flex justify-content-between fs-7">
        <span className={styles.caption}>Estimated Tax</span>
          {tax.state === "loading" ? (
            <></>
          ) : tax.state === "hasError" ? (
            <span>
              error
            </span>
          ) : (
            <span>
              {currencySymbol}{((formattedPrice ?? 0) * tax.data).toFixed(2)}
            </span>
          )}
      </div>
      {discount ? (
        <div className="d-flex justify-content-between mt-3 fs-7">
          <span className={styles.caption}>Discounts:</span>
          <span>
            <span className={styles.caption}>{discount.codeName}</span>
            <span style={{ marginLeft: "0.5em" }}>
              {`-${currencySymbol}${calcDiscountFromCouponOnPrice(
                price,
                taxRate,
                discount
              ).toFixed(2)}`}
            </span>
          </span>
        </div>
      ) : null}
      <div className="d-flex justify-content-between mt-3 fs-5 position-relative">
        <span className={styles.caption}>Payment Due Today</span>
        {discount ? (
          <>
            <span>
              {`${currencySymbol}${
                calcPriceAfterDiscountOnPrice(price, taxRate, discount) === 0
                  ? "1.00"
                  : calcPriceAfterDiscountOnPrice(price, taxRate, discount).toFixed(2)
              }`}
            </span>
            {calcPriceAfterDiscountOnPrice(price, taxRate, discount) === 0 ? (
              <span className={styles.verificationChargeCaption}>
                One-time verification charge
              </span>
            ) : null}
          </>
        ) : (
          <span>${formattedPriceWithTax?.toFixed(2) ?? NaN}</span>
        )}
      </div>
      {!displayToggleBanner &&
        (displayToggleWhenAnnual || interval === Interval.MONTHLY) && (
          <div
            className="d-flex justify-content-end mt-3 fs-7"
            style={{ color: "var(--link-color)" }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              style={{ cursor: "pointer" }}
              href={undefined}
              onClick={(e) => {
                if (
                  price?.interval === Interval.ANNUAL &&
                  discount?.code === DISCOUNT_CODE_2_YEAR
                ) {
                  setDiscount(null);
                }
                toggleCoverageInterval({
                  e: e,
                  searchParams: searchParams,
                  setSearchParams: setSearchParams,
                  internalInterval: price?.interval ? price.interval : null,
                  remoteSetLead,
                });
              }}
            >
              Switch to {price?.interval === "annual" ? "monthly" : "annual"}{" "}
              {interval === Interval.MONTHLY && savingsFormattedReadOnly != null
                ? `- Save
              ${savingsFormattedReadOnly}!`
                : null}
            </a>
          </div>
        )}
      <div className="d-flex justify-content-between mt-3">
        <span className={styles.caption}>
          Billable after
          {discount?.code === DISCOUNT_CODE_2_YEAR ? ` second` : ` first`}
          {interval === Interval.MONTHLY ? ` month` : ` year`}
        </span>
        <div className="d-flex flex-column justify-content-end">
          <span className="text-end">
            {currencySymbol}
            {formattedPriceWithTax?.toFixed(2) ?? ""} /{" "}
            {price?.interval === "annual" ? "year" : "month"}
          </span>
          {interval === Interval.ANNUAL &&
            Math.abs(savingOnChangeCoverage) > 1 &&
            !discount && (
              <div className="d-flex align-items-center justify-content-end mt-1 fs-7">
                <PercentageIcon className={styles.percentageIcon} />
                <span className="fs-7 ms-1 lh-1" style={{ color: "#2F7D22" }}>
                  You're Saving {`${savingsFormattedReadOnly}!`}
                </span>
              </div>
            )}
        </div>
      </div>
      {displayToggleBanner &&
        (displayToggleWhenAnnual || interval === Interval.MONTHLY) && (
          <div className="mt-3 mb-4">
            <CostSummaryAnnualToggle
              annualSavings={`${savingsFormattedReadOnly}`}
            />
          </div>
        )}
    </div>
  );
};
