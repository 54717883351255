import { atom } from "jotai";
import { customerAtom } from "./customer";
import { termOfServiceAcceptedAtom } from "./terms-or-service";
import { paymentInformationAtom } from "./payment-information";
import { productAtom, selectedPriceAtom } from "./product";
import {
  PaymentInformation,
  PaymentMethod,
} from "../types/payment-information";
import { intervalAtom } from "./interval";
import { Customer } from "../types/customer";
import { Interval } from "../types/interval";
import { ProductInterface, ProductPriceListing } from "../types/product";
import { loadable } from "jotai/utils";
import { readDiscountAtom } from "./discount";
import { DiscountCode } from "../types/discount";
import {
  partnerPropertyUrlQueryValueAtom,
  salesRepUrlQueryValueAtom,
  partnerPropertyFriendlyUrlQueryValueAtom,
  salesRepFriendlyUrlQueryValueAtom,
} from "./partner";
import { 
  DISCOUNT_CODE_2_YEAR
} from "../constants/promotion"

export const isDataCompleteForRegistrationAtom = atom<boolean>((get) => {
  const customer = get(customerAtom);
  const termOfServiceAccepted = get(termOfServiceAcceptedAtom);
  const paymentInformation = get(paymentInformationAtom);

  if (paymentInformation.paymentMethod === PaymentMethod.CARD) {
    if (
      !customer.emailAddress ||
      !customer.lastName ||
      !customer.firstName ||
      !customer.phone ||
      !customer.isPhoneValid ||
      !customer.postalCode
    ) {
      return false;
    }

    if (!paymentInformation.stripeToken) {
      return false;
    }
  }

  if (paymentInformation.paymentMethod === PaymentMethod.ACH) {
    if (!customer.emailAddress) {
      return false;
    }

    if (
      !paymentInformation.plaidClientId ||
      !paymentInformation.plaidPublicToken ||
      !paymentInformation.bankAccountId ||
      !customer.postalCode
    ) {
      return false;
    }
  }

  if (!termOfServiceAccepted) {
    return false;
  }

  return true;
});

const createRegistrationPayloadForCard = (
  product: ProductInterface | null,
  selectedPrice: ProductPriceListing | null,
  customer: Customer,
  paymentInformation: PaymentInformation,
  interval: Interval,
  discount: DiscountCode | null,
  partner_property_id: string,
  sales_representative_id: string,
  partner_property_friendly_id: string,
  sales_representative_friendly_id: string,
  irclickid: string | null
) => {
  return {
    products: [
      {
        id: product?.id,
        name: product?.name,
        phone: customer.phone,
        productPrice: {
          id: selectedPrice?.id,
          interval: selectedPrice?.interval,
          name: selectedPrice?.name,
          price: selectedPrice?.currentPrice
        },
        primary: customer.primary,
        make: "",
        model: "",
      },
    ],
    discounts: discount
      ? [
          {
            amount: discount.amount,
            amount_type: discount.amountType,
            code: discount.code,
            code_name: discount.codeName,
            coupon: discount.coupon,
            deductible_credit_value: discount.deductibleCreditValue,
            discount_level: discount.discountLevel,
            entered: true,
            expiration_date: discount.expirationDate,
            number_months: discount.numberMonths,
            referral_code: discount.referralCode,
          },
        ]
      : [],
    interval: interval,
    primary_email: customer.emailAddress,
    address: customer.address,
    postal_code: customer.postalCode,
    site_url: `${process.env.REACT_APP_SITE_URL}`,
    two_year_upfront: discount?.code === DISCOUNT_CODE_2_YEAR ? true : false,
    stripe_token: paymentInformation.stripeToken,
    card_first_name: customer.firstName,
    card_last_name: customer.lastName,
    method: "CREDIT_DEBIT_CARD",
    sales_rep: sales_representative_id ? Number(sales_representative_id) : null,
    sales_rep_friendly_id: sales_representative_friendly_id,
    partner_property: partner_property_id ? Number(partner_property_id) : null,
    partner_property_friendly_id: partner_property_friendly_id,
    redirect_to_upsell: true,
    ...(irclickid && { irclickid: irclickid }),
  };
};

const createRegistrationPayloadForAch = (
  product: ProductInterface | null,
  selectedPrice: ProductPriceListing | null,
  customer: Customer,
  paymentInformation: PaymentInformation,
  interval: Interval,
  discount: DiscountCode | null,
  partner_property_id: string,
  sales_representative_id: string,
  partner_property_friendly_id: string,
  sales_representative_friendly_id: string,
  irclickid: string | null
) => {
  return {
    products: [
      {
        id: product?.id,
        name: product?.name,
        phone: customer.phone,
        productPrice: {
          id: selectedPrice?.id,
          interval: selectedPrice?.interval,
          name: selectedPrice?.name,
          price: selectedPrice?.currentPrice
        },
        primary: customer.primary,
        make: "",
        model: "",
      },
    ],
    discounts: discount
      ? [
          {
            amount: discount.amount,
            amount_type: discount.amountType,
            code: discount.code,
            code_name: discount.codeName,
            coupon: discount.coupon,
            deductible_credit_value: discount.deductibleCreditValue,
            discount_level: discount.discountLevel,
            entered: true,
            expiration_date: discount.expirationDate,
            number_months: discount.numberMonths,
            referral_code: discount.referralCode,
          },
        ]
      : [],
    interval: interval,
    primary_email: customer.emailAddress,
    address: customer.address,
    postal_code: customer.postalCode,
    site_url: `${process.env.REACT_APP_SITE_URL}`,
    two_year_upfront: discount?.code === DISCOUNT_CODE_2_YEAR ? true : false,
    method: "ACH",
    account_id: paymentInformation.bankAccountId,
    plaid_client_id: paymentInformation.plaidClientId,
    public_token: paymentInformation.plaidPublicToken,
    sales_rep: sales_representative_id ? Number(sales_representative_id) : null,
    sales_rep_friendly_id: sales_representative_friendly_id,
    partner_property: partner_property_id ? Number(partner_property_id) : null,
    partner_property_friendly_id: partner_property_friendly_id,
    redirect_to_upsell: true,
    ...(irclickid && { irclickid: irclickid }),
  };
};

const asyncRequestResponseAtom = atom<any | null>(null);

export const loadableRequestResponseAtom = loadable(asyncRequestResponseAtom);

export const registrationRequestAtom = atom(
  null,
  async (get, set, irclickid?: string) => {
    const customer = get(customerAtom);
    const paymentInformation = get(paymentInformationAtom);
    const selectedPrice = get(selectedPriceAtom);
    const product = get(productAtom);
    const interval = get(intervalAtom);
    const discount = get(readDiscountAtom);
    const partner_property_id = get(partnerPropertyUrlQueryValueAtom);
    const partner_property_friendly_id = get(partnerPropertyFriendlyUrlQueryValueAtom);
    const sales_representative_id = get(salesRepUrlQueryValueAtom);
    const sales_representative_friendly_id = get(salesRepFriendlyUrlQueryValueAtom);
    const res = fetch(
      paymentInformation.paymentMethod === PaymentMethod.CARD
        ? `${process.env.REACT_APP_API}/v2/registrations/`
        : `${process.env.REACT_APP_API}/registrations/ach/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          paymentInformation.paymentMethod === PaymentMethod.CARD
            ? createRegistrationPayloadForCard(
                product,
                selectedPrice,
                customer,
                paymentInformation,
                interval,
                discount,
                partner_property_id,
                sales_representative_id,
                partner_property_friendly_id,
                sales_representative_friendly_id,
                irclickid || null
              )
            : createRegistrationPayloadForAch(
                product,
                selectedPrice,
                customer,
                paymentInformation,
                interval,
                discount,
                partner_property_id,
                sales_representative_id,
                partner_property_friendly_id,
                sales_representative_friendly_id,
                irclickid || null
              )
        ),
      }
    );
    set(asyncRequestResponseAtom, res);
  }
);

export const requestResponseAtom = atom<any | Response>((get) => {
  const loadableRequestResponse = get(loadableRequestResponseAtom);
  if (!loadableRequestResponse) return null;
  if (loadableRequestResponse.state === "hasError") return null;
  if (loadableRequestResponse.state === "loading") return null;

  return loadableRequestResponse.data;
});

const asyncRequestResponseTextAtom = atom<Promise<null | string>>((get) => {
  const requestResponse = get(requestResponseAtom);
  if (requestResponse) {
    return requestResponse.text();
  }
  return new Promise(() => null);
});

const asyncRequestResponseJsonAtom = atom<Promise<null | any>>((get) => {
  const requestResponse = get(requestResponseAtom);
  if (requestResponse && requestResponse.ok) {
    return requestResponse.json();
  }
  return new Promise(() => null);
});

export const loadableRequestResponseTextAtom = loadable(
  asyncRequestResponseTextAtom
);

export const loadableRequestResponseJsonAtom = loadable(
  asyncRequestResponseJsonAtom
);

export const resetRegistrationRequestResponseAtom = atom(null, (_get, set) => {
  set(asyncRequestResponseAtom, null);
});
