import { ProductPriceListing } from "../types/product";
import { Interval } from "../types/interval";
import { DiscountCode } from "../types/discount";
import { DISCOUNT_CODE_2_YEAR } from "../constants/promotion"

export const calcDiscountFromCouponOnPrice = (
  price: ProductPriceListing | null,
  taxRate: number,
  discount: DiscountCode
): number => {
  if (price === null) {
    return NaN;
  }
	console.log(price, taxRate, discount);
  if (discount.amountType === "PERCENT") {
    if (price.interval === Interval.MONTHLY) {
      return (discount.amount / 100) * ((price.currentPrice * taxRate) / 100);
    } else {
      // in case of annual coverage
      // in case of 2 year upfront discount
      if (discount.code === DISCOUNT_CODE_2_YEAR) {
        return (
          2 * (discount.amount / 100) *
          ((price.currentPrice * taxRate) / 100 / 12) *
          (discount.numberMonths ?? 12)
        );
      } else {
        return (
          (discount.amount / 100) *
          ((price.currentPrice * taxRate) / 100 / 12) *
          (discount.numberMonths ?? 12)
        );
      }
    }
  } else {
    //   At the moment of implementation no other type of discount was implemented
    return NaN;
  }
};

export const calcPriceAfterDiscountOnPrice = (
  price: ProductPriceListing | null,
  taxRate: number,
  discount: DiscountCode
): number => {
  if (price === null) {
    return NaN;
  }
  if (discount.amountType === "PERCENT") {
    if (price.interval === Interval.MONTHLY) {
      return (
        (price.currentPrice * taxRate) / 100 -
        (discount.amount / 100) * ((price.currentPrice * taxRate) / 100)
      );
    } else {
      // in case of annual coverage
      // in case of 2 year upfront discount
      if (discount.code === DISCOUNT_CODE_2_YEAR) {
        return (
          2 * (price.currentPrice * taxRate) / 100 -
          (discount.amount / 100) *
            ((price.currentPrice * taxRate) / 100 / 12) *
            (discount.numberMonths ?? 12)
        );
      } else {
        return (
          (price.currentPrice * taxRate) / 100 -
          (discount.amount / 100) *
            ((price.currentPrice * taxRate) / 100 / 12) *
            (discount.numberMonths ?? 12)
        );
      }
    }
  } else {
    //   At the moment of implementation no other type of discount was implemented
    return NaN;
  }
};
